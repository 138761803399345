require('./bootstrap');

$(function () {
    $(document).scroll(function () {
      var $nav = $(".navbar");
      $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
    });
  });

let scroll = $(window).scrollTop();
if($(window).width() <= 991 || scroll > 10) {
    $('.menu').addClass('sticky');
}

if(isHome) {
    let hT = $('#offer').offset().top - $('.menu').outerHeight();
    let hO =  $('#offer').offset().top + $('#offer').outerHeight(true) - 100;
    let scroll = $(window).scrollTop();
    if(scroll >= hT && scroll <= hO) {
        $('.nav-item.offer').addClass('active');
    } else {
        $('.nav-item.offer').removeClass('active');
    }

    $(window).on('scroll', () => {
        let scroll = $(window).scrollTop();
        if(scroll >= hT && scroll <= hO) {
            $('.nav-item.offer').addClass('active');
        } else {
            $('.nav-item.offer').removeClass('active');
        }
    });
}


let counterInit = false;

$(window).on('scroll', () => {
    let scroll = $(window).scrollTop();
    let width = $(window).width();

    if(scroll > 10 || width <= 991) {
        $('.menu').addClass('sticky');
    } else {
        $('.menu').removeClass('sticky');
    }

    if(!counterInit && isHome) {
        let hT = $('.counter').offset().top,
            hH = $('.counter').outerHeight(),
            wH = $(window).height();
        if (scroll > (hT+hH-wH)){

            counterInit = true;
            $('.counter-item').each(function() {
                let $this = $(this),
                    countTo = $this.attr('data-count');

                $({ countNum: $this.text()}).animate({
                countNum: countTo
                },

                {

                duration: 7000,
                easing:'linear',
                step: function() {
                    $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                    $this.text(this.countNum);
                    //alert('finished');
                }

                });
            });
        }
    }
 });


 $('form').on('submit', function() {
    $(this).find('.submit-from').slideToggle();
    $(this).find('.progress-form').slideToggle();
 });
